/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import {
  Image, Skeleton, Tooltip,
} from 'antd';
import { getTransText } from 'common/translate';
import ContentUnavailable from 'components/atoms/EmptyData/ContentUnavailable';
import RecentlyViewEmptyData from 'components/atoms/EmptyData/RecentlyEmptyData';
import LinkLegacy from 'components/atoms/LinkLegacy/LinkLegacy';
import IcEditorial from 'components/icons/Page/IcEditorial';
import IcSimilarImages from 'components/icons/Page/IcSimilarImages';
import IcVector from 'components/icons/Page/IcVector';
import IcDownloaded from 'components/icons/SearchBar/IcDownloaded';
import PopUpMessageForCollectionsCreated from 'components/pages/AuthorizedPages/Dashboard/MyCollection/MyCollectionMessage/PopUpMessageForCollectionsCreated';
import {
  AI_ICON,
  EDITORIAL_ICON_IMAGE, LANG, SIMILAR_SEARCH_KEYWORD,
  VECTOR_ICON, WHITE_COLOR, WIDTH_MOBILE, WIDTH_TABLET,
} from 'constants/index';
import { LANGUAGE_CODE_DEFAULT } from 'constants/intl';
import { ROUTES } from 'constants/routes';
import * as cookie from 'helpers/cookies';
import justifiedLayout from 'justified-layout';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, {
  // useContext,
  useEffect, useRef, useState,
} from 'react';
// import MainContext from 'stores/context/MainContexts';
import IcAIGenerated from 'components/icons/IcAIGenerated';
import SimilarDetailLoading from 'components/pages/PublicPages/DetailPage/ImageDetail/SimilarDetailLoading';
import { isEmpty } from 'lodash';
import DropdownCollection from '../DefaultCollection/DropdownCollection';
import IconAdd from '../DefaultCollection/IconAdd';
import MessagePopup from '../DefaultCollection/MessagePopup';

const CustomJustifiedLayout = ({
  GeometriesDesktop,
  GeometriesTablet,
  GeometriesMobile,
  listImage,
  contentLoading,
  targetRowHeight,
  sizePadding,
  vectorButton,
  recentlyViewed,
  showWidows,
  GeometriesDesktopLoader,
  GeometriesTabletLoader,
  GeometriesMobileLoader,
  addLikebox,
  maxNumRowsDesktop,
  maxNumRowsMobile,
  detailSimilar,
  containerImages,
}) => {
  const router = useRouter();
  const locale = router.query?.lang ?? cookie.getCookies(LANG) ?? LANGUAGE_CODE_DEFAULT;
  const [geometry, setGeometry] = useState({ boxes: [] });
  const [geometryLoader, setGeometryLoader] = useState({ boxes: [] });
  const [getInnerWitdh, setInnerWitdh] = useState(0);
  const [maxNumRows, setMaxNumRows] = useState(maxNumRowsDesktop);
  // Likebox Collection
  const [isAdd, setIsAdd] = useState(false);
  const [isRemove, setIsRemove] = useState(false);
  const [idImageAdd, setIdImageAdd] = useState('');
  const [defaultLB, setDefaultLB] = useState('');
  const [description, setDescription] = useState('');
  const [isCreated, setIsCreated] = useState(false);
  const [likeboxName, setLBname] = useState('');
  // const [timeoutLoading, setTimeoutLoading] = useState(false);
  // const { actionsSearchResult } = useContext(MainContext);

  const [dataClone, setDataClone] = useState({});
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDataClone(listImage);
    }
  }, [listImage]);
  const checkSizeGeometries = (size, type = 0) => {
    if (size >= WIDTH_TABLET) {
      setMaxNumRows(maxNumRowsDesktop);
      return type === 0 ? GeometriesDesktop : GeometriesDesktopLoader;
    }
    if (size < WIDTH_TABLET && size > WIDTH_MOBILE) {
      setMaxNumRows(maxNumRowsDesktop);
      return type === 0 ? GeometriesTablet : GeometriesTabletLoader;
    }
    setMaxNumRows(maxNumRowsMobile);
    return type === 0 ? GeometriesMobile : GeometriesMobileLoader;
  };

  // resize geometry
  useEffect(() => {
    const containerWidth = containerImages ?? getInnerWitdh;

    setGeometry(
      justifiedLayout(checkSizeGeometries(containerWidth), {
        targetRowHeight,
        containerWidth: containerWidth - sizePadding.exceptContainerWidth,
        containerPadding: containerWidth > sizePadding.sizePadding ? {
          top: sizePadding.paddingTop,
          right: sizePadding.paddingRight,
          bottom: sizePadding.paddingBottom,
          left: sizePadding.paddingLeft,
        } : {
          top: 0,
          right: 15,
          bottom: 5,
          left: 15,
        },
        showWidows,
        boxSpacing: {
          horizontal: 4,
          vertical: 4,
        },
        maxNumRows,
      }),
    );
    setGeometryLoader(
      justifiedLayout(checkSizeGeometries(containerWidth, 1), {
        targetRowHeight,
        containerWidth: containerWidth - sizePadding.exceptContainerWidth,
        containerPadding: containerWidth > sizePadding.sizePadding ? {
          top: sizePadding.paddingTop,
          right: sizePadding.paddingRight,
          bottom: sizePadding.paddingBottom,
          left: sizePadding.paddingLeft,
        } : {
          top: 0,
          right: 15,
          bottom: 5,
          left: 15,
        },
        showWidows: false,
        boxSpacing: {
          horizontal: 4,
          vertical: 4,
        },
        maxNumRows,
      }),
    );
  }, [getInnerWitdh, sizePadding.exceptContainerWidth,
    GeometriesDesktop, GeometriesTablet, GeometriesMobile, maxNumRows, listImage]);

  const getDefaultLikebox = (defaultName, Imgdescription) => {
    setDefaultLB(defaultName);
    setDescription(Imgdescription);
  };

  const onChangeAdd = (add, imageIdAdd) => {
    setIsAdd(add);
    setIdImageAdd(imageIdAdd);
  };
  const onChangeRemove = (remove, imageIdAdd) => {
    setIsRemove(remove);
    setIdImageAdd(imageIdAdd);
  };
  const actionLikeboxRef = useRef(null);
  const undoAdded = () => {
    setIsAdd(false);
    actionLikeboxRef?.current?.handleRemoveToLikebox(idImageAdd, false);
  };

  const undoRemoved = () => {
    setIsRemove(false);
    actionLikeboxRef?.current?.handleAddtoLikebox(idImageAdd, false);
  };
  // Message Create Collection
  const onCreateNewLikebox = (created, lbName) => {
    setIsCreated(created);
    setLBname(lbName);
  };
  const handleTimeout = (timeout) => {
    setIsCreated(timeout);
  };

  // Open similar search
  const openSimilarSearch = (imageId) => {
    router.push({
      pathname: ROUTES.SEARCH_IMAGE,
      query: {
        keyword: `${SIMILAR_SEARCH_KEYWORD}${imageId}`, page: '1', lang: locale,
      },
    });
  };

  useEffect(() => {
    const resize = () => {
      let getWidth = window.innerWidth;
      if (getWidth === 0) {
        getWidth = window.innerWidth;
      }
      setInnerWitdh(getWidth);
    };
    resize();
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, [contentLoading]);

  const images = React.useMemo(() => geometry?.boxes.map((box, index) => {
    const style = {
      ...box,
      position: 'absolute',
    };
    const styleImg = {
      width: box.width,
      height: box.height,
    };
    // const handelDetailImage = async (imageId, added) => {
    //   await router.push(`/imageDetail?imageid=${imageId}`);
    //   await actionsSearchResult.setDataIsAddedAudioDetail(added);
    // };
    return (
      <div className="jutified-img-wrapper" style={style} key={index.toString()}>
        {isEmpty(listImage?.[index]?.src) ? <ContentUnavailable className="content-unavailable-photo" />
          : (
            <>
              {
                listImage?.[index]?.first_image ? (
                  <>
                    <Image
                      preview={false}
                      src={listImage?.[index]?.src}
                      style={styleImg}
                      className="first_image_justified"
                      loading="lazy"
                    />
                    <div className="bottom-first-reverse-image">{getTransText('uploadedImage')}</div>
                  </>
                )
                  : (
                    <>
                      <LinkLegacy
                        href={
                          listImage?.[index]?.detail_url
                            ? `${listImage?.[index]?.detail_url}${router.query?.keyword ? `&keyword=${router.query.keyword}` : ''}`
                            : `${ROUTES.DETAIL_IMAGE_PAGE}?imageid=${listImage?.[index]?.image_id}${router.query?.keyword ? `&keyword=${router.query.keyword}` : ''}`
                        }
                      >
                        <span className="jutified-img-text">
                          {listImage?.[index]?.desc}
                        </span>
                        <div className="jutified-img-overlay" />
                        <Image
                          preview={false}
                          src={listImage?.[index]?.src}
                          style={styleImg}
                          className="jutified-img"
                          loading="lazy"
                        />
                      </LinkLegacy>
                      <div className="jutified-img-menu-top">
                        {listImage?.[index]?.downloaded === 1 && (
                          <div className="float-right">
                            <Tooltip overlayClassName="downloaded-tooltip" title={getTransText('downloaded')}>
                              <div>
                                <IcDownloaded />
                              </div>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                      <div className="jutified-img-menu">
                        <div className="display-img-icon">
                          <div className="display-flex text-left">
                            {(vectorButton || listImage?.[index]?.icon_link === VECTOR_ICON) && (
                              <Tooltip title={getTransText('vectors')}>
                                <div>
                                  <IcVector
                                    fillcolor={WHITE_COLOR}
                                    width="24"
                                    height="24"
                                  />
                                </div>
                              </Tooltip>
                            )}
                            {listImage?.[index]?.icon_link === EDITORIAL_ICON_IMAGE && (
                              <Tooltip title={getTransText('editorial')}>
                                <div style={{ display: 'flex' }}>
                                  <IcEditorial
                                    fillcolor={WHITE_COLOR}
                                    width="24"
                                    height="24"
                                  />
                                </div>
                              </Tooltip>
                            )}
                            {listImage?.[index]?.icon_link === AI_ICON && (
                            <Tooltip title={getTransText('aiGenerate')}>
                              <div style={{ display: 'flex' }}>
                                <IcAIGenerated
                                  fillcolor={WHITE_COLOR}
                                  width="24"
                                  height="24"
                                />
                              </div>
                            </Tooltip>
                            )}
                          </div>
                          <div className="display-img-icon-right">
                            {typeof (listImage?.[index]?.added) === 'number' && (
                              <>
                                <DropdownCollection
                                  sizeImage={+listImage?.[index]?.sizeImage}
                                  imageId={+listImage?.[index]?.id}
                                  onCreateNewLikebox={onCreateNewLikebox}
                                  onChangeAdd={onChangeAdd}
                                  onChangeRemove={onChangeRemove}
                                  getDefaultLikebox={getDefaultLikebox}
                                  descriptionImg={listImage?.[index]?.desc}
                                  dataClone={dataClone}
                                />
                                <Tooltip title={getTransText('addToLikebox')}>
                                  <IconAdd
                                    dataClone={dataClone}
                                    image={dataClone?.[index]}
                                    setDataClone={setDataClone}
                                    onChangeAdd={onChangeAdd}
                                    onChangeRemove={onChangeRemove}
                                    ref={actionLikeboxRef}
                                    getDefaultLikebox={getDefaultLikebox}
                                    descriptionImg={listImage?.[index]?.desc}
                                  />
                                </Tooltip>
                              </>
                            )}
                            <div onClick={() => openSimilarSearch(listImage?.[index]?.id ?? listImage?.[index]?.image_id)} aria-hidden="true">
                              <Tooltip title={getTransText('similarImage')}>
                                <div className="display-flex similar-image">
                                  <IcSimilarImages
                                    width="24"
                                    height="24"
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
              }
            </>
          )}
      </div>
    );
  }), [geometry, dataClone]);
  const height = parseFloat(geometry?.containerHeight);
  const heightLoader = parseFloat(geometryLoader?.containerHeight);

  // if get listImage slow then show content loader
  const contentLoader = React.useMemo(() => geometryLoader?.boxes.map((box, index) => {
    const style = {
      ...box,
      position: 'absolute',
    };
    return (
      <div className="jutified-img-wrapper" style={style} key={index.toString()}>
        <div className="content-loader-image">
          <Skeleton.Image />
        </div>
      </div>
    );
  }), [geometry]);
  return (
    <>
      {contentLoading
        ? detailSimilar
          ? (<SimilarDetailLoading />)
          : (
            <div style={{
              height: `${heightLoader}px`,
              position: 'relative',
            }}
            >
              {contentLoader}
            </div>
          )
        : (
          <>
            {recentlyViewed ? (
              <>
                {!isEmpty(listImage) ? (
                  <div style={{
                    height: `${height}px`,
                    position: 'relative',
                  }}
                  >
                    {images}
                  </div>
                ) : <RecentlyViewEmptyData />}
              </>
            )
              : (
                <div style={{
                  height: `${height}px`,
                  position: 'relative',
                }}
                >
                  {images}
                  <MessagePopup
                    defaultLB={defaultLB}
                    description={description}
                    undoAdded={undoAdded}
                    undoRemoved={undoRemoved}
                    isAdd={isAdd}
                    isRemove={isRemove}
                    addLikebox={addLikebox}
                    onChangeAdd={onChangeAdd}
                    onChangeRemove={onChangeRemove}
                  />
                  <PopUpMessageForCollectionsCreated
                    isCreated={isCreated}
                    likeboxName={likeboxName}
                    handleTimeout={handleTimeout}
                  />
                </div>
              )}
          </>
        )}
    </>
  );
};

CustomJustifiedLayout.propTypes = {
  GeometriesDesktop: PropTypes.arrayOf(PropTypes.any),
  GeometriesTablet: PropTypes.arrayOf(PropTypes.any),
  GeometriesMobile: PropTypes.arrayOf(PropTypes.any),
  listImage: PropTypes.arrayOf(PropTypes.any),
  targetRowHeight: PropTypes.number,
  sizePadding: PropTypes.objectOf(PropTypes.any),
  vectorButton: PropTypes.bool,
  contentLoading: PropTypes.bool,
  recentlyViewed: PropTypes.bool,
  showWidows: PropTypes.bool,
  GeometriesDesktopLoader: PropTypes.arrayOf(PropTypes.any),
  GeometriesTabletLoader: PropTypes.arrayOf(PropTypes.any),
  GeometriesMobileLoader: PropTypes.arrayOf(PropTypes.any),
  addLikebox: PropTypes.bool,
  maxNumRowsDesktop: PropTypes.number,
  maxNumRowsMobile: PropTypes.number,
  detailSimilar: PropTypes.bool,
  containerImages: PropTypes.any,
};

CustomJustifiedLayout.defaultProps = {
  GeometriesDesktop: [],
  GeometriesTablet: [],
  GeometriesMobile: [],
  listImage: [],
  targetRowHeight: 250,
  sizePadding: {},
  vectorButton: false,
  contentLoading: null,
  recentlyViewed: null,
  showWidows: false,
  GeometriesDesktopLoader: [],
  GeometriesTabletLoader: [],
  GeometriesMobileLoader: [],
  addLikebox: true,
  maxNumRowsDesktop: 999,
  maxNumRowsMobile: 999,
  detailSimilar: false,
  containerImages: undefined,
};

export default CustomJustifiedLayout;
