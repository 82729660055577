import { getTransText } from 'common/translate';
import { ICON_COLOR_DEFAULT, RECENT_VIEW } from 'constants/index';
import {
  IcPhotos, IcVector, IcFootage, IcAudio,
} from 'components/icons';
import { ROUTES } from 'constants/routes';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const RecentlyViewEmptyData = ({ mediaType }) => {
  const router = useRouter();
  const [isShow, setIsShow] = useState(<></>);
  const typeData = router?.query?.type ?? RECENT_VIEW;
  const rVPhoto = (
    <>
      <div><IcPhotos fillcolor={ICON_COLOR_DEFAULT} /></div>
      <div>{typeData === RECENT_VIEW ? getTransText('rVPhoto') : getTransText('rLPhoto') }</div>
    </>
  );
  const rVVectors = (
    <>
      <div>
        <IcVector fillcolor={ICON_COLOR_DEFAULT} />
      </div>
      <div>{typeData === RECENT_VIEW ? getTransText('rVVectors') : getTransText('rLVectors') }</div>
    </>
  );
  const rVFootage = (
    <>
      <div>
        <IcFootage fillcolor={ICON_COLOR_DEFAULT} />
      </div>
      <div>{typeData === RECENT_VIEW ? getTransText('rVFootage') : getTransText('rLFootage') }</div>
    </>
  );
  const rVAudio = (
    <>
      <div>
        <IcAudio fillcolor={ICON_COLOR_DEFAULT} />
      </div>
      <div>{typeData === RECENT_VIEW ? getTransText('rVAudio') : getTransText('rLAudio') }</div>
    </>
  );
  useEffect(() => {
    if (mediaType === -1) {
      switch (router.pathname) {
        case ROUTES.HOME: return setIsShow(rVPhoto);
        case ROUTES.VECTOR: return setIsShow(rVVectors);
        case ROUTES.FOOTAGE: return setIsShow(rVFootage);
        case ROUTES.AUDIO: return setIsShow(rVAudio);
        default:
          break;
      }
    } else {
      switch (mediaType) {
        case 0:
        case 1:
          return setIsShow(rVPhoto);
        case 2: return setIsShow(rVVectors);
        case 3: return setIsShow(rVFootage);
        case 4: return setIsShow(rVAudio);
        default:
          break;
      }
    }
    return null;
  }, [router.pathname, mediaType]);
  return (
    <div className="container-wrapper recently-view-empty-img">
      <div className="divide-space" />
      {isShow}
    </div>
  );
};
RecentlyViewEmptyData.propTypes = {
  mediaType: PropTypes.number,
};

RecentlyViewEmptyData.defaultProps = {
  mediaType: -1,
};
export default RecentlyViewEmptyData;
