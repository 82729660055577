import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

const SimilarDetailLoading = () => (
  <div className="similar-detail-loading-container">
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 100, color: '#ffd44d' }} />}
    />
  </div>
);

export default SimilarDetailLoading;
