import PropTypes from 'prop-types';
import { MAIN_COLOR } from 'constants/index';

const IcDownloaded = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.8647 0.000778235C20.3999 0.000801452 20.6675 0.000813012 20.8337 0.171259C21 0.341706 21 0.616026 21 1.16467L21 19.054C21 20.2309 21 20.8194 20.653 20.9695C20.306 21.1196 19.8948 20.709 19.0724 19.8877L1.1576 1.99759C0.308351 1.14951 -0.116275 0.725473 0.027611 0.362699C0.171496 -7.5747e-05 0.764297 -5.02017e-05 1.9499 1.21325e-06L19.8647 0.000778235Z" fill={fillcolor} />
  </svg>
);
IcDownloaded.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcDownloaded.defaultProps = {
  fillcolor: MAIN_COLOR,
  width: '21',
  height: '21',
};
export default IcDownloaded;
