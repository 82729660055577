import { getTransText } from 'common/translate';
import Ic123RF from 'components/icons/HeaderMenu/Ic123RF';
import PropTypes from 'prop-types';

const ContentUnavailable = ({ className, witdh, height }) => (
  <div className={`content-unavailable ${className} text-center`}>
    <Ic123RF witdh={witdh} height={height} />
    <div>{getTransText('contentUnavailable')}</div>
    <span>{getTransText('contentError')}</span>
  </div>
);
ContentUnavailable.propTypes = {
  className: PropTypes.string,
  witdh: PropTypes.string,
  height: PropTypes.string,
};

ContentUnavailable.defaultProps = {
  className: '',
  witdh: '48',
  height: '48',
};

export default ContentUnavailable;
