import PropTypes from 'prop-types';
import { ICON_COLOR_DEFAULT } from 'constants/index';

const IcEditorial = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 9.36842V38C8 39.8603 9.50809 41.3684 11.3684 41.3684H36.6316C38.4919 41.3684 40 39.8603 40 38V9.36842C40 7.50809 38.4919 6 36.6316 6H11.3684C9.50809 6 8 7.50809 8 9.36842ZM11.4597 10.3132C11.4597 9.83677 11.8459 9.45056 12.3223 9.45056H34.8133C35.2897 9.45056 35.6759 9.83677 35.6759 10.3132C35.6759 10.7896 35.2897 11.1758 34.8133 11.1758H12.3224C11.8459 11.1758 11.4597 10.7896 11.4597 10.3132ZM27.8898 14.6264C27.4134 14.6264 27.0271 15.0127 27.0271 15.4891C27.0271 15.9655 27.4134 16.3517 27.8898 16.3517H34.8131C35.2896 16.3517 35.6758 15.9655 35.6758 15.4891C35.6758 15.0127 35.2896 14.6264 34.8131 14.6264H27.8898ZM27.0271 20.665C27.0271 20.1885 27.4134 19.8023 27.8898 19.8023H34.8131C35.2896 19.8023 35.6758 20.1885 35.6758 20.665C35.6758 21.1414 35.2896 21.5276 34.8131 21.5276H27.8898C27.4134 21.5276 27.0271 21.1414 27.0271 20.665ZM27.8898 24.9782C27.4134 24.9782 27.0271 25.3644 27.0271 25.8408C27.0271 26.3172 27.4134 26.7034 27.8898 26.7034H34.8131C35.2896 26.7034 35.6758 26.3172 35.6758 25.8408C35.6758 25.3644 35.2896 24.9782 34.8131 24.9782H27.8898ZM11.4597 31.0167C11.4597 30.5403 11.8459 30.154 12.3223 30.154H34.8133C35.2897 30.154 35.6759 30.5403 35.6759 31.0167C35.6759 31.4931 35.2897 31.8793 34.8133 31.8793H12.3224C11.8459 31.8793 11.4597 31.4931 11.4597 31.0167ZM12.3223 35.3299C11.8459 35.3299 11.4597 35.7161 11.4597 36.1926C11.4597 36.669 11.8459 37.0552 12.3224 37.0552H34.8133C35.2897 37.0552 35.6759 36.669 35.6759 36.1926C35.6759 35.7161 35.2897 35.3299 34.8133 35.3299H12.3223ZM23.5675 14.6264H13.1891V26.7035H23.5675V14.6264Z" fill={fillcolor} />
  </svg>

);
IcEditorial.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcEditorial.defaultProps = {
  fillcolor: ICON_COLOR_DEFAULT,
  width: '48',
  height: '48',
};
export default IcEditorial;
